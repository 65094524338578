import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import { history } from "../../helpers/history";
import clientmanagmentObj from "../../services/client-managment.service";
import depositService from "../../services/deposit.service";
const Navigation = (props) => {
  const [activeClass, setActiveClass] = useState();
  const [depositcount, setDepositcount] = useState(0);
  const [changepincount, setChangepincount] = useState(0);
  const applyActiveClass = (id) => {
    setActiveClass(id);
  };

  const getCountAPIs = () => {
    depositService.getDepositCounts(
      "", "Pending", "0001-01-01", "0001-01-01", "0001-01-01", "0001-01-01", "", 1, ""
    ).then((resp) => {
      setDepositcount(resp?.data?.payload?.result?.totalCount);
    }).catch();
    clientmanagmentObj.getChangePinCount("", "", "0001-01-01T00:00:00.000Z", "0001-01-01T00:00:00.000Z", "", 1).then((resp) => {
      setChangepincount(resp?.data?.payload?.result?.totalCount)
    }).catch((error) => {

    });
  }

  useEffect(() => {
    setTimeout(() => {
      setActiveClass(window.location.pathname.replace("/", ""));
    }, 200);
    getCountAPIs();
  }, []);
  const closeLeftMenu = () => {
    if (document.getElementById("boMenu").classList.contains("bo-menu-overlay")) {
      document.getElementById("boMenu").classList.remove("bo-menu-overlay");
      document.getElementById("body").style = "overflow: visible";
      document.getElementById("leftNav").style.cssText =
        "position: unset; width: 0; height: 0; z-index: ; display : none";
    }
  }

  return (
    <div className="col-lg-3 col-md-0 bo-sidebar-col" id="leftNav">
      <div className="bo-sidebar-outer">
        <Link
          to="/dashboard"
          className={
            activeClass === "dashboard" || activeClass === "" ? "active" : ""
          }
          onClick={() => { applyActiveClass("dashboard"); closeLeftMenu(); }}
        >
          <img src={logo} className="img-fluid" alt="BO Payments" />
        </Link>
        {

        }
        <ul className="sidebar-ul">
          {props.role !== null && (
            <Fragment>
              {(props.role.includes("Super admin")) ? (
                <Fragment>
                  <li>
                    <Link
                      id="dashboardNavLink"
                      to={"/dashboard"}
                      className={
                        activeClass === "dashboard" || activeClass === "" ? "active" : ""
                      }
                      onClick={() => { applyActiveClass("dashboard"); closeLeftMenu(); getCountAPIs(); }}
                    >
                      <i className="icon-dashboard"></i>Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/cards"}
                      id="cardsNavLink"
                      className={activeClass === "cards" ? "active" : ""}
                      onClick={() => { applyActiveClass("cards"); closeLeftMenu(); }}
                    >
                      <i className="icon-card"></i>Cards
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/ibanaccounts"}
                      id="IbanAccountNavLink"
                      className={activeClass === "ibanaccounts" ? "active" : ""}
                      onClick={() => { applyActiveClass("ibanaccounts"); closeLeftMenu(); }}
                    >
                      <i className="icon-card"></i>IBAN
                    </Link>
                  </li>
                  
                  <li>
                    <Link
                      to={"/clients"}
                      id="clientNavLink"
                      className={activeClass === "clients" ? "active" : ""}
                      onClick={() => { applyActiveClass("clients"); closeLeftMenu(); }}
                    >
                      <i className="icon-client"></i>Clients
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/superclients"}
                      id="clientNavLink"
                      className={activeClass === "superclients" ? "active" : ""}
                      onClick={() => { applyActiveClass("superclients"); closeLeftMenu(); }}
                    >
                      <i className="icon-client"></i>Super Clients
                    </Link>
                  </li>
                  {
                    props?.depositpermission && (
                      <li>
                        <Link
                          id="depositNavLink"
                          to={"/deposit"}
                          className={activeClass === "deposit" ? "active" : ""}
                          onClick={() => { applyActiveClass("deposit"); closeLeftMenu(); }}
                        >
                          <i className="icon-deposit"></i>Deposit
                          <span className="bo-badge bo-badge-large">
                            {depositcount}</span>
                        </Link>
                      </li>
                    )
                  }
                  <li>
                    <Link
                      id="moneyNavLink"
                      to={"/sendmoney"}
                      className={activeClass === "money" ? "active" : ""}
                      onClick={() => { applyActiveClass("money"); closeLeftMenu(); }}
                    >
                      <i className="icon-transaction"></i>Send Money

                    </Link>
                  </li>
                  {
                    props?.rolemanagmentpermission && (
                      <li>
                        <Link
                          id="rolesNavLink"
                          to={"/rolemanagment"}
                          className={activeClass === "rolemanagment" ? "active" : ""}
                          onClick={() => { applyActiveClass("rolemanagment"); closeLeftMenu(); }}
                        >
                          <i className="icon-RoleManagement"></i>Role Management
                        </Link>
                      </li>
                    )
                  }
                  <li>
                    <Link
                      id="KeyNavLink"
                      to={"/changepins"}
                      className={activeClass === "changepins" ? "active" : ""}
                      onClick={() => { applyActiveClass("changepins"); closeLeftMenu(); }}
                    >
                      <i className="icon-ChangePIN"></i>Change Pin
                      <span className="bo-badge bo-badge-large">
                        {changepincount}</span>
                    </Link>
                  </li>
                  {
                    props?.FeeManagement &&(
                      <li>
                        <Link
                          id="FeeNavLink"
                          to={"/feemanagments"}
                          className={activeClass === "feemanagments" ? "active" : ""}
                          onClick={() => { applyActiveClass("feemanagments"); closeLeftMenu(); }}
                        >
                          <i className="icon-Wallet"></i>Fee Management 
                        </Link>
                      </li>
                    )
                  }
                      
                  <li>
                    <Link
                      id="settingsNavLink"
                      to={"/settings"}
                      className={activeClass === "settings" ? "active" : ""}
                      onClick={() => { applyActiveClass("settings"); closeLeftMenu(); }}
                    >
                      <i className="icon-settings"></i>Settings
                    </Link>
                  </li>
                  
                  <hr />
                  <li>
                    <Link
                      to={"/login"}
                      className="d-flex align-items-center logout"
                      onClick={() => { props.logOut(); closeLeftMenu(); }}
                    >
                      <i className="icon-logout"></i>
                      <span>
                        <span>Logout</span>
                        <br />
                        <span className="user-name">{props.username}</span>
                      </span>
                    </Link>
                  </li>

                </Fragment>
              ) : (
                <div>

                  {history.push("/login")}

                  <Link
                    to={"/login"}
                    className="d-flex align-items-center logout"
                    onClick={() => { props.logOut(); closeLeftMenu(); }}
                  >
                    <i className="icon-logout"></i>
                    <span>
                      <span>Logout</span>
                      <br />
                      <span className="user-name">{props.username}</span>
                    </span>
                  </Link>
                </div>
              )}
            </Fragment>
          )}


        </ul>
      </div>
    </div>
  );
};
export default Navigation;
